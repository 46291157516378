<template>
    <div>
        <van-cell-group title="填写单据详情">
            <van-cell v-for="(it, idx) in grid" :key="idx" :title="it.ST_NAME" v-model="it.AMT" :label="it.CNT"
                @click="itemEdit(it, idx)">
                <template #default>￥{{ it.AMT.toFixed(2) }}元</template>

                <template #label>
                    {{ it.CNT }} ({{ it.ST_UNIT }})
                    <span v-if="it.DESC"> | {{ it.DESC }}</span>
                </template>
            </van-cell>
            <van-cell title="合计">
                ￥{{ model.AMT.toFixed(2) }}元
            </van-cell>
        </van-cell-group>
        <div style="margin: 15px">
            <van-button type="info" size="large" icon="plus" block plain @click="itemEdit()">增加物品</van-button>
            <br />
            <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                loading-text="正在提交。。。">确认提交</van-button>
            <br />
            <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
        </div>
        <van-popup v-model="ipt.show" position="bottom" round>
            <van-field readonly label="分类" placeholder="请选择分类" is-link @click="ipt.cat.show = true"
                v-model="item.CAT_NAME" />
            <van-popup v-model="ipt.cat.show" round position="bottom">
                <van-cascader title="请选择分类" :options="ipt.cat.ls" @finish="catSel" @close="ipt.cat.show = false"
                    v-model="item.CAT_ID" :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
            </van-popup>
            <van-field readonly label="物品" placeholder="请选择物品" is-link @click="onStockClick" v-model="item.ST_NAME"
                required />
            <van-popup v-model="ipt.stock.show" round position="bottom">
                <van-search @search="onStockSearch" v-model="ipt.stock.key" show-action>

                    <template #action>
                        <van-button type="info" size="small" style="vertical-align: middle"
                            @click="onStockSearch">搜索</van-button>
                    </template>
                </van-search>
                <van-picker :columns="ipt.stock.lss" show-toolbar @cancel="ipt.stock.show = false"
                    @confirm="stockChange">

                    <template slot-scope="scope" slot="option">
                        <van-cell :value="scope.BALANCE">
                            <template #title>
                                {{ scope.NAME }} 【{{ scope.FUNIT }}】
                            </template>
                        </van-cell>
                    </template>
                </van-picker>
            </van-popup>
            <van-field v-model="item.CNT" type="digit" label="数量" placeholder="请输入数量" required />
            <van-field v-if="model.CAT == 100" v-model="item.AMT" type="number" label="金额" placeholder="请输入金额"
                required />

            <van-field v-if="model.CAT == 200" readonly label="所属班级" placeholder="请选择班级" is-link
                @click="ipt.cla.show = true" v-model="item.CLASS_NAME" />
            <van-popup v-model="ipt.cla.show" round position="bottom">
                <van-picker :columns="ipt.cla.ls" show-toolbar @cancel="ipt.cla.show = false" @confirm="classSel"
                    value-key="NAME" />
            </van-popup>

            <van-field v-if="model.CAT == 200" label="所属位置" placeholder="右边查询按钮选择" v-model="item.LOCATION"
                @click="ipt.loc.show = true">

                <template #right-icon>
                    <van-icon name="search" @click="ipt.loc.show = true" />
                </template>
            </van-field>

            <!-- <van-popup v-model="ipt.loc.show" round position="bottom">
          <van-picker :columns="ipt.loc.ls" show-toolbar @cancel="ipt.loc.show = false" @confirm="locSel" />
        </van-popup> -->

            <van-popup v-model="ipt.loc.show" round position="bottom">
                <van-cascader title="请选择分类" :options="ipt.loc.ls" @finish="locSel" @close="ipt.loc.show = false"
                    :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
            </van-popup>


            <van-field v-model="item.DESC" type="text" label="备注" />
            <div style="text-align: center;margin: 5px 15px;" v-if="item.PIC_URL">
                <van-image :src="item.PIC_URL" style="border: solid 1px #ccc; border-radius: 5px" />
            </div>
            <div style="padding: 20px; text-align: center">
                <van-button type="info" size="normal" icon="success" plain @click="itemOk">完 成</van-button>
                <van-button v-if="ipt.idx >= 0" type="warning" size="normal" icon="close" plain @click="itemDel"
                    style="margin-left: 30px">删 除</van-button>
                <van-button v-else type="primary" size="normal" icon="share-o" plain @click="itemNext"
                    style="margin-left: 30px">下一个</van-button>
            </div>
        </van-popup>
    </div>
</template>

<style scoped></style>

<script>
import { Toast } from 'vant';
export default {
    name: "new",
    data() {
        return {
            model: { AMT: 0, CAT: 100 },
            item: {},
            grid: [],
            ret: {
                loading: false
            },
            ipt: {
                show: false,
                cat: {
                    show: false,
                    ls: [],
                    txt: ""
                },
                stock: {
                    show: false,
                    ls: [],
                    txt: "",
                    lss: [],
                    key: ""
                },
                cla: {
                    show: false,
                    ls: [],
                },
                loc: {
                    show: false,
                    ls: [],
                },
                idx: -1
            },
            id: 0
        }
    },
    created() {
        this.model.CAT = this.$route.query.cat;
        this.id = JSON.parse(sessionStorage.getItem('editMat')).ID
        this.grid = JSON.parse(sessionStorage.getItem('editMat')).gridg
        this.model = JSON.parse(sessionStorage.getItem('editMat'))
        this.getCat();
        this.getSource();
        if (this.model.CAT == 200) {
            this.getClass();
            this.getLoc();
        }

    },
    methods: {
        getSource() {
            let sid = this.$route.query.sid;
            if (sid != undefined) {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/MAT/MVActApi/Get",
                    data: { ID: sid },
                    completed: function (m) {
                        self.grid = JSON.parse(m.DATA.GBODY);
                        self.model = m.DATA;
                        self.ipt.show = false;
                        console.log(self.model.AMT)
                    }
                })
            }
        },
        getCat() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/MAT/MCatApi/GetTree",
                completed: function (its) {
                    self.ipt.cat.ls = its.DATA;
                }
            })
        },
        catSel({ selectedOptions }) {
            this.ipt.cat.show = false;
            this.item.CAT_NAME = selectedOptions.map((option) => option.NAME).join('/');
            this.getStock();
        },
        onStockClick() {
            this.ipt.stock.show = true;
            this.getStock();
        },
        getStock() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/MAT/MStockApi/GetList",
                data: { CAT_ID: this.item.CAT_ID },
                completed: function (its) {
                    self.ipt.stock.ls = its.ITEMS;
                    self.ipt.stock.lss = its.ITEMS;

                    self.item.ST_ID = 0;
                    self.item.ST_NAME = "";
                }
            })
        },
        stockChange(o) {
            this.ipt.stock.show = false;
            this.ipt.stock.txt = o.NAME;
            this.item.ST_ID = o.ID;
            this.item.ST_NAME = o.NAME;
            this.item.ST_UNIT = o.FUNIT;
            this.item.ST_PRICE = o.PRICE;
            this.item.CAT_NAME = o.CAT_NAME;
            this.item.CAT_ID = o.CAT_ID;
            this.item.BALANCE = o.BALANCE;
            this.item.PIC_URL = o.PIC_URL;
        },
        onStockSearch() {
            this.ipt.stock.lss = this.ipt.stock.ls.filter(o => {
                return o.NAME.indexOf(this.ipt.stock.key) >= 0
            })
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/MAT/MStockApi/GetList",
                data: { KEY: this.ipt.stock.key },
                completed: function (its) {
                    // self.model.ST_ID = "";
                    self.ipt.stock.ls = its.ITEMS;
                    self.ipt.stock.lss = its.ITEMS;
                }
            })
        },
        getClass() {
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/ORG/ClassApi/GetList",
                completed: function (its) {
                    self.ipt.cla.ls = its.ITEMS;
                }
            })
        },
        classSel(o) {
            this.item.CLASS_ID = o.ID;
            this.item.CLASS_NAME = o.NAME;
            this.item.LOCATION = ''
            this.ipt.cla.show = false;
        },
        getLoc() {
            // let self = this;
            // this.whale.remote.getCollection({
            //   url: "/api/Mobile/FX/DictApi/GetList",
            //   data: {
            //     TYPE: 'MAT_VALID_LOC'
            //   },
            //   completed: function (its, n) {
            //     if (n > 0) {
            //       self.ipt.loc.ls = its.ITEMS[0].VALUE.split(',');
            //     }
            //   }
            // })
            let self = this;
            this.whale.remote.getCollection({
                url: "/api/Mobile/FIX/LocationApi/GetTree",
                completed: function (its) {
                    self.ipt.loc.ls = its;
                }
            })
        },
        locSel({ selectedOptions }) {
            this.item.LOCATION = selectedOptions.map((option) => option.NAME).join('/');
            this.item.CLASS_NAME = ''
            this.item.CLASS_ID = 0;
            this.ipt.loc.show = false;
        },
        itemEdit(o, i) {
            if (o == undefined) {
                this.item = {};
                this.ipt.cat.txt = "";
                this.ipt.idx = -1;
            } else {
                this.item = Object.assign({}, o);
                this.ipt.cat.txt = o.CAT_NAME;
                this.ipt.idx = i;
            }
            this.ipt.show = true;
        },
        itemDel() {
            this.grid = this.grid.filter((x, i) => {
                if (i != this.ipt.idx) return true;
                else {
                    this.model.AMT -= x.AMT;
                    return false;
                }
            });
            this.ipt.show = false;
        },
        itemOk() {
            // if (this.item.ST_ID == undefined) {
            //   this.ipt.show = false;
            //   return
            // }
            if (this.item.ST_ID == 0) {
                Toast.fail("请输入物品名称");
                return
            }
            if (this.item.CNT == undefined || parseFloat(this.item.CNT) <= 0) {
                Toast.fail("请输入数量");
                return;
            }
            if (this.model.CAT == 100) {
                if (this.item.AMT == undefined || this.item.AMT <= 0) {
                    Toast.fail("请输入金额,且大于0")
                    return
                }
                this.item.AMT = parseFloat(this.item.AMT);
            } else {
                this.item.AMT = this.item.ST_PRICE * this.item.CNT;
                if (this.item.CNT > this.item.BALANCE) {
                    Toast.fail(`${this.item.ST_NAME} 库存不足， (库存：${this.item.BALANCE} ${this.item.ST_UNIT})。`)
                    return
                }
            }
            if (this.model.CAT == 200 && !this.item.CLASS_NAME && !this.item.LOCATION) {
                Toast.fail("请选择所属班级或所属位置")
                return
            }
            this.item.RAMT = this.item.AMT;
            if (this.ipt.idx >= 0) {
                this.grid.map((o, i) => {
                    if (i == this.ipt.idx) {
                        this.model.AMT -= o.AMT;
                        Object.assign(o, this.item);
                    }
                })
            } else {
                this.grid.push(Object.assign({}, this.item));
            }
            this.model.AMT += this.item.AMT;
            this.ipt.show = false;
        },
        itemNext() {
            if (this.item.ST_ID == undefined) {
                Toast.fail("请选择物品")
                return
            }
            if (this.model.CAT == 100) {
                if (this.item.AMT == undefined || this.item.AMT <= 0) {
                    Toast.fail("请输入金额，且大于0")
                    return
                }
                this.item.AMT = parseFloat(this.item.AMT);
            } else {
                if (this.item.CNT == undefined) {
                    Toast.fail("请输入数量")
                    return
                }
                if (this.item.CNT > this.item.BALANCE) {
                    Toast.fail(`${this.item.ST_NAME} 库存不足， (库存：${this.item.BALANCE} ${this.item.ST_UNIT})。`)
                    return
                }
                this.item.AMT = this.item.ST_PRICE * this.item.CNT;
            }
            if (this.model.CAT == 200 && !this.item.CLASS_NAME && !this.item.LOCATION) {
                Toast.fail("请选择所属班级或所属位置")
                return
            }
            this.item.RAMT = this.item.AMT;
            this.grid.push(Object.assign({}, this.item));
            this.model.AMT += this.item.AMT;

            this.item.CNT = undefined;
            this.item.AMT = undefined;
            this.item.ST_ID = undefined;
            this.item.ST_NAME = '';
        },
        submit() {
            let self = this;
            if (this.grid.length == 0) {
                Toast.fail("没有物品")
                return
            }
            // if (this.grid.length>5){
            //   Toast.fail("对不起，一次最多只能提交5条记录")
            //   return
            // }
            if (this.model.CAT == 200) {
                if ((this.grid.CLASS_NAME == '' && this.grid.LOCATION != '') || (this.grid.CLASS_NAME != '' && this.grid.LOCATION == '')) {
                    Toast.fail("请填写班级或位置")
                    return
                }
            }
            let tt = [];
            this.grid.map(x => { tt.push(x.ST_NAME); })
            this.grid.map(i => {
                if (i.CLASS_ID == '') {
                    i.CLASS_ID = 0
                }
                if (i.LOCATION == '') {
                    i.LOCATION = 0
                }
            })
            this.model.ID = this.id
            this.model.TITLE = tt.join();
            this.model.GBODY = JSON.stringify(this.grid);
            self.ret.loading = true;
            this.whale.remote.getResult({
                url: "/api/Mobile/MAT/MVActApi/Update",
                data: this.model,
                finally() { self.ret.loading = false; },
                completed() {
                    self.$dialog.alert({
                        title: "完成",
                        message: "记录已成功提交！",
                        theme: "round",
                    }).then(() => { self.$router.go(-1); })
                }
            })
        }
    }
}
</script>